import React from 'react';
import { graphql, Link } from 'gatsby'
import styled from "styled-components"
import Layout from '../layouts'
import {
  isMobile, isBrowser
} from "react-device-detect";


const Author = ({ data }) => {  
  const {title,
    name,
    description, 
    thumbnail,
    article } = data.contentfulAuthor
    
  return (
    <Layout>
      <Container style={{Container}}>
        <div className='body'> 
          <h1>{title}</h1>
          <img 
            src={thumbnail.resize?.src} 
            alt={thumbnail.description}
            style={{ width: isMobile ? '200px' : '' } }
            />
        </div>
      
        <h1>{name}</h1>
        <h3>{description.description}</h3>

        {
          article ? (        
            article.map(art => (
              <Link key={art.slug} to={`/blog/${art.slug}`}>
                <Article>
                  <img 
                    src={art.thumbnail?.resize.src} 
                    alt={art.thumbnailText}
                    style={{ width: isMobile ? '100px' : '',
                      justifySelf: isBrowser ? 'right' : '',
                      marginRight: isBrowser ? '50px' : ''
                    }}
                    />
                    <span
                    >{art.title}</span>              
                </Article>
              </Link>
              )
            )
          ) : null
        }
      </Container>
    </Layout>
  )
}


const Container = styled.div`    
  background: ${props => props.theme.background};
  margin: 0;
  padding-bottom: 20px;

  li, pre, code {
    font-size: 22px;
    color: ${props => props.theme.text};
    opacity: .87;
    font-family: sans-serif;
  }
  h1, h2, h3, h4 {
    color: ${props => props.theme.text};
    opacity: .87;
    text-align: center;
    padding: 0px 25px 0px 25px;
    font-size: 35px;
    font-family: sans-serif;
    margin-bottom: 25px;
    letter-spacing: 1.2px;
    line-height:125%;
    word-spacing: 6px;
  }
  pre {
    color: ${props => props.theme.text};
    opacity: .87;
    margin: 0px 0px 0px 0px;
    font-family: sans-serif;
  }
  p, pre {
    font-size: 22px;
    margin-top: 25px;
    margin-bottom: 0px;
    padding: 0px 25px 0px 25px;
    color: ${props => props.theme.text};
    opacity: .87;
    text-indent: 0em;
    text-align: left;
    font-family: sans-serif;
    word-spacing: 6px;
    line-height:125%;
    letter-spacing: 1.2px;
  }
  img {
    max-width: 100%;
  }
  blockquote {
    background-color: antiquewhite;
  }

  .toggleHeader {
    display: flex;
    flex: 1;
    padding: 20px 50px 20px 25px;
    justify-content: flex-end;
    align-items: center;
    
    span {
      color: ${props => props.theme.text};
      font-weight: bold;  
      
    }

  }

  .body{
    max-width: 800px;
    margin: 0 auto;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    span {
      color: ${props => props.theme.text};
      font-weight: bold;
      margin-left: 25px;
      font-size: 18px;
    }

    img {
      border-radius: 50%;
    }
  }
`

const Article = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  justify-content: center;
  align-items: center;
  margin: 25px 0px 25px 0px;
  

  img {
    justify-self: center;
  }

  span {
    color: ${props => props.theme.text};
    font-weight: bold;
    font-size: 18px;
    max-width: 80%;
    opacity: .87;
    font-family: sans-serif;
  }

  &:hover {
    opacity: 0.5;
    &:after {
      opacity: 1;
    }
  }
  

`;

export default Author;

export const pageQuery = graphql`
  query authorQuery($slug: String!) {
    contentfulAuthor(slug: {eq: $slug}) {
      title
      slug
      name
      description {
        description
      }
      thumbnail {
        resize(height: 350, width: 350) {
          src
        }
      description
      }

      article {
      slug
      date(formatString: "MM-DD-YYYY")
      thumbnail {
        resize(height: 150, width: 150) {
          src
        }
        title
      }
      thumbnailText
      title
    }

    }
  }

`